.prescription-card {
  &__edit {
    &--disabled {
      path {
        fill: $color-tertiary-dark;
      }
    }
  }

  &__other-medication {
    font-size: 0.8em;
  }

  &__label {
    &.prescription-card__label {
      color: $color-tertiary-dark;
      font-size: 0.8em;
      font-weight: normal;
    }
  }

  &__value {
    &.prescription-card__value {
      font-weight: bold;
      font-size: 0.8em;
    }
  }

  &__add {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 3em;
    padding-bottom: 3em;

    &-text {
      margin-top: 0.5em;

      font-weight: bold;
      color: $color-primary;
    }
  }
}
