.dose-element {
  background-color: $color-tertiary-dark;

  .dose-slider {
    .MuiSlider-mark {
      height: 0.5em;
    }
  }

  &__dose-label {
    font-size: 0.75em;
  }

  &__label--deleteable {
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    h3 {
      margin: 0;
    }

    .icon-subtract {
      width: 1em;
      height: 1em;

      margin-right: 0.5em;
    }
  }
}

.dose-form {
  &__other-medication {
    width: 100%;

    .MuiFormControlLabel-label {
      font-size: 0.85em;
    }

    .icon-checkbox {
      height: 1em;
      width: 1em;
    }
  }

  &__add-dose {
    &.dose-form__add-dose {
      margin: 0em 0.5em;
      padding: 0.5em;

      .icon-add {
        width: 1em;
        height: 1em;

        margin-right: 0.5em;
      }
    }
  }
}
