.credits {
  &__logo {
    max-width: 80%;
    max-height: 10em;

    object-fit: contain;

    &--partner {
      max-height: 6em;
    }
  }
}
