.page-closeable {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-primary;

  z-index: 10;

  overflow-y: auto;
  overflow-x: hidden;
  @include no-scrollbar();

  &--footer-buttons {
    padding-bottom: 4rem;
  }

  &.page {
    .page {
      &__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &__body {
        background-color: $color-background;
      }
    }
  }

  // Equal flex pressure from both sides.
  &__close {
    flex-basis: 50%;

    cursor: pointer;

    &--right {
      text-align: right;
    }
  }

  &__title {
    white-space: nowrap;
  }
}
