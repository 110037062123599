.add-child-page {
  p {
    font-size: 1.5em;
    font-weight: bold;
  }
  .page__header {
    background-color: $color-primary;
  }
  .add-child-page__back-button__icon {
    transform: rotate(180deg);
    path {
      fill: $color-secondary;
    }
  }
  .add-child-page__back-button__text {
    font-weight: bold;
    color: $color-secondary;
  }
}
.add-child-selector__image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  border: 1px solid #c7b3f2;
  height: 3em;
  width: 3em;
}
.add-child-selector__image {
  background-color: "#fff";
  height: 2em;
  width: 2em;
}
.add-child-selector__text-container {
  p {
    font-size: 1em;
    font-weight: normal;
  }
  &__selected {
    p {
      font-size: 1em;
      font-weight: bold;
      color: $color-primary;
    }
  }
}
