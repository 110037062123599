.followup-selector {
  border: none;

  width: auto;

  .icon {
    margin-left: 1em;
  }

  .select-fullscreen__menu-value {
    font-size: 0.85em;
    line-height: 1.5em;
  }
}
