.intensity-form {
  width: 100%;
  p {
    font-weight: bold;
  }
  .intensity-form__answer-text {
    color: $color-primary;
  }
}

.intensity-form__answer-icon {
  background-color: $color_primary;
  height: 6em;
  width: 6em;
  &__selected {
    background-color: #c7b3f2;
    height: 6em;
    width: 6em;
    .feedback-icon__grid {
      height: 100%;
    }
  }
  .feedback-icon__grid {
    height: 100%;
  }
}
