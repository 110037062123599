.alert-root {
  z-index: 1000;
  position: fixed;
  left: 0.5em;
  right: 0.5em;
  bottom: 4em;

  display: flex;
  flex-direction: column-reverse;
}
