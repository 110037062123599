.icon-button {
  &.icon-button {
    min-width: 0;
    width: 3em;
    height: 3em;
    padding: 1em;
  }

  svg {
    height: 1.5em;
    width: 1.5em;
  }

  path {
    fill: $color-background;
  }
}
