.date-picker {
  margin-top: 1em;

  &-container {
    display: flex;
    flex-direction: row-reverse;

    overflow-x: auto;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__label {
    margin-bottom: 1em;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-option {
    &__wrapper {
      flex-basis: 25%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    @include no-user-select();

    position: relative;

    box-sizing: border-box;
    margin-right: 0.25em;
    margin-left: 0.25em;

    border-radius: 1em;
    background-color: $color-tertiary;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75em 0.5em;

    &__date {
      margin-top: 0.25em;
      font-size: 1.25rem;
    }

    &--selected {
      background-color: $color-tertiary-dark;
    }

    &__tag {
      position: absolute;
      top: 0;
      right: -0.25em;

      width: 1em;
      height: 1em;

      border-radius: 0.5em;
      background-color: $color-error;
    }
  }
}

// Customisations for Material-UI date picker.
.MuiPickersStaticWrapper-staticWrapperRoot.MuiPickersStaticWrapper-staticWrapperRoot {
  background: none;
}

.MuiButtonBase-root.MuiPickersCalendarHeader-iconButton {
  background: none;
}

.MuiPickersDay-current.MuiPickersDay-current {
  color: $color-text;
}

.MuiPickersDay-daySelected.MuiPickersDay-daySelected {
  background-color: $color-secondary;
  color: $color-primary;

  &:hover {
    background-color: $color-secondary;
  }
}

.MuiIconButton-label.MuiIconButton-label {
  height: 100%;

  p {
    line-height: 1em;
  }
}

.mui-date-picker__day {
  padding: 0 2px;
  button {
    margin: 0;
  }

  &--highlight {
    margin: -2px 0;

    &-start {
      margin: -2px 0;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      border-left: 1px solid black;
    }

    &-middle {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
    }

    &-end {
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      border-top: 1px solid black;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      padding: 0;
      margin-right: 2px;
    }
  }
}
