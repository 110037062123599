.side-effect-card__container {
  background-color: $color-primary;
  font-size: 1.25em;
  .completed-badge__container.completed-badge__container {
    height: 100%;
    .completed-badge.completed-badge {
      background-color: $color-tertiary-dark;
      width: 3em;
      height: 80%;
      border-radius: 1em 1em 0 0;
      .completed-badge__icon-container {
        height: 100%;
      }
    }
  }
}
