.frequency-form {
  width: 100%;
  height: 100%;
  .frequency-form__grid {
    height: 100%;
    .frequency-form__input-container {
      width: 100%;
      .frequency-form__frequency-slider {
        .MuiSlider-rail {
          color: $color-primary;
          height: 0.25em;
          opacity: 1;
          border-radius: 0.125em;
        }
        .MuiSlider-track {
          height: 0.25em;
          opacity: 1;
          border-radius: 0.125em;
        }
        .MuiSlider-mark {
          color: $color-primary;
          width: 0.25em;
          height: 0.5em;
          opacity: 1;
          border-radius: 0.125em;
        }
      }
    }
    .frequency-form__slider-text {
      font-size: 1.25em;
      font-weight: bold;
    }
  }
}
