.select-fullscreen {
  &__input {
    position: relative;
    border-bottom: 2px solid black;

    padding: 0.5em 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
  }

  &__menu {
    &.MuiDrawer-paperAnchorBottom {
      height: 100vh;
      border-radius: 0;

      background-color: gray;
    }

    &-header {
      padding: 1em;
      display: flex;
      align-items: center;

      .text-input {
        flex-grow: 1;
        background-color: #e5e5e5;

        padding: 1em;

        input {
          font-size: 0.75em;
        }
      }

      &-cancel {
        margin-right: 1em;
        align-self: stretch;
        border-radius: 1em;
        background: none;
        border: none;
        padding: 0 0.5em;

        user-select: none;
        cursor: pointer;

        font-size: 0.75em;

        &:active {
          background-color: lightgray;
        }
      }
    }

    &-values {
      display: flex;
      flex-direction: column;
    }

    &-value {
      &-wrapper {
        cursor: pointer;

        padding: 0 1em;

        &--selected {
          background-color: lightgray;
        }

        transition: background-color 0.3s ease;
      }

      // Transparency gives impression of thinner line.
      border-bottom: 1px solid #ffffff44;

      color: white;

      padding: 0.75em 0;
    }
  }
}
