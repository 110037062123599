.option-page__menu.option-page__menu {
  width: 100%;
  border-radius: 0;
  background-color: $color-tertiary-light;

  .option-page__header {
    font-weight: bold;
    color: $color-secondary;
    background-color: $color-tertiary-light;
  }

  .option-card__container {
    background-color: $color-primary;
    font-size: 1em;
  }

  .option-page__link {
    text-decoration: none;
  }

  .build-number {
    font-size: 0.75em;
    font-weight: bold;
  }
}
