.navigation-tabs {
  &.navigation-tabs {
    min-height: 0;
    height: 2em;
    border-radius: 1em;
  }

  background-color: $color-background;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);

  &__button {
    &.navigation-tabs__button {
      width: 10em;
      min-height: 0;
      margin-left: -0.5em;
      padding: 0 1em 0 1em;
      border-radius: 1em;

      font-weight: normal;
      text-transform: none;

      &--first {
        margin-right: -0.5em;
      }
    }

    &.Mui-selected {
      background-color: $color-background;
      box-shadow: (0px 2px 5px 0px rgba(0, 0, 0, 0.25));
      font-weight: bold;
    }
  }

  &__flex-container {
    justify-content: center;
    height: 100%;
  }
}
