.question-page {
  .page__header {
    background-color: $color-primary;
    .question-page__close-element {
      color: $color-error;
    }
    .page-closeable__title {
      color: $color-secondary;
    }
  }
  .question-page__grid {
    height: 100%;
    .question-page__step-indicator__container {
      background-color: $color-secondary;
      color: $color-primary;
      height: 2.75em;
      width: 2.75em;
      .question-page__step-indicator__grid {
        height: 100%;
        .question-page__step-indicator__text {
          font-weight: bold;
          font-size: 1.25em;
        }
      }
    }
    .question-page__form-container {
      background-color: $color-tertiary-dark;
      width: 100%;
      height: 95%;
      border-radius: 20px 20px 0 0;
      .question-page__form-container__grid {
        height: 90%;
        .question-page__question-text-container {
          width: 100%;
          .question-page__question-text {
            font-weight: bold;
          }
        }
      }
    }
  }
  .question-page__footer-container {
    .footer-buttons {
      background-color: $color-primary;
    }
  }
}
