.app-navigator {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .MuiBottomNavigationAction {
    &-root {
      path {
        fill: $color-primary;
      }
    }

    &-root.Mui-selected {
      path {
        fill: $color-error;
      }
    }

    &-label {
      margin-top: 0.5em;
      font-weight: bold;

      &.Mui-selected {
        color: $color-error;
        font-size: 0.75em;
      }
    }
  }
}
