.text-input {
  border-radius: 0.5em;
  background-color: white;
  padding: 0.5em 1em;

  display: flex;
  align-items: center;

  cursor: text;

  border: none;
  background: none;

  font-size: 1em;
  line-height: 1.25em;

  resize: none;

  input {
    margin-left: 0.5em;
  }

  &:active,
  &:focus {
    outline: none;
  }
}
