.button--add {
  &.button--add {
    width: 3em;
    height: 3em;
    min-width: 0;
    padding: 0;
  }

  svg {
    width: 40%;
    height: 40%;
  }

  path {
    fill: $color-primary;
  }
}
