.icon-menu-button {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1em;

  &__button {
    &.icon-menu-button__button {
      margin-bottom: 0.5em;

      box-sizing: border-box;
      min-width: 0;
      width: 3em;
      height: 3em;
      border-radius: 1em;

      padding: 0;
    }
  }

  &__text {
    font-weight: bold;
  }
}
