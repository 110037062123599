.alert--error {
  color: $color-error;

  .icon-alert {
    margin-right: 0.5em;

    path {
      fill: $color-error;
    }
  }
}
