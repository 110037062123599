.ampm-selector {
  display: flex;
  justify-content: center;
  align-items: center;

  &__selector {
    &.ampm-selector__selector {
      width: 100%;
      min-width: fit-content;
      margin: auto;

      border-radius: 1em;
    }
  }
}
