.journal-page {
  &__tabs {
    position: fixed;
    top: 5em;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
  }
}

@import "./Notes/NotesPage.scss";
@import "./observationDisplay/index";
