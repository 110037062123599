.sideeffect-selector {
  overflow-x: scroll;
  @include no-scrollbar();

  padding-right: 1em;

  .sideeffect-selector__option {
    white-space: nowrap;
    font-weight: normal;
    min-width: 0;
  }
}
