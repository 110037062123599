$color-primary: #fff;
$color-secondary: #000;
$color-tertiary-light: #f2f2f2;
$color-tertiary: #e5e5e5;
$color-tertiary-light: #f2f2f2;
$color-tertiary-dark: #c4c4c4;
$color-text: #000;
$color-text-secondary: #fff;
$color-filter: #00000088;
$color-error: #ff002e;
$color-success: #3ea40e;
$color-highlight: #31737c;

$color-background: $color-primary;
