.menu-drawer {
  &__paper {
    &.menu-drawer__paper {
      background-color: $color-primary;
    }
  }

  &__divider {
    width: 80%;
    border: 1px solid $color-tertiary-dark;
  }
}
