.sideeffect-form {
  &__search {
    background-color: $color-tertiary;
  }

  &__filter {
    &.sideeffect-form__filter {
      min-width: auto;

      &--selected {
        background-color: $color-error;

        &:hover {
          background-color: $color-error;
        }
      }
    }
  }

  &__title {
    margin-top: -0.375em;
    display: flex;
    align-items: center;

    font-size: 0.75em;
    &-line {
      margin-left: 0.5em;
      flex-grow: 1;
      border-bottom: 1px solid $color-tertiary;
      height: 0;
    }
  }

  &__value {
    border-top: 1px solid $color-tertiary;

    &--first {
      border-top: none;

      margin-top: -2px;
    }

    .icon {
      width: 1em;
      height: 1em;
    }

    label {
      width: 100%;
      justify-content: flex-start;
    }
  }

  &__error {
    position: absolute;
    left: 1em;
    right: 1em;
    bottom: calc(100% + 1em);
  }
}
