.notes-page {
  background-color: $color-tertiary-light;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: auto;
  @include no-scrollbar();

  &.notes-page {
    padding-top: 6em;
    padding-bottom: 4em;
  }

  &__add-button {
    &.notes-page__add-button {
      background-color: $color-background;
    }
  }
}

.notes-display {
  flex-grow: 1;
  padding-bottom: 1em;

  &.notes-display {
    background-color: $color-background;
  }

  &__title {
    font-weight: bold;

    border-bottom: 1px solid $color-secondary;
  }

  &__item {
    border-bottom: 1px solid $color-secondary;
  }
}

.note-form {
  &__time-picker {
    background-color: $color-tertiary-dark;
    border-radius: 999px;
    color: $color-primary;
    font-weight: bold;

    cursor: pointer;

    padding-left: 1em;
    padding-right: 1em;
  }

  &__text-input {
    border-radius: 0;
    padding: 0;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    text-align: left;
    input {
      margin: 0;
    }

    &--title {
      &.note-form__text-input--title {
        border-bottom: 2px solid $color-secondary;
      }
    }

    &-count {
      padding-top: 0.25em;
      font-size: 0.75em;

      &--error {
        color: $color-error;
      }
    }
  }
}

.edit-note-form {
  &__delete-button {
    color: $color-error;
  }
}
