@import "./stylesheets/main.scss";
@import "./commons/components";
@import "./commons/hooks";
@import "./pages";

html,
body {
  margin: 0;
  padding: 0;
}

.container {
  height: 50vh;
  width: 50vw;
  border: 1px solid $color-secondary;
  position: absolute;
  top: 25vh;
  left: 25vw;
  padding: 25px;
}

.grecaptcha-badge {
  visibility: hidden;
}
