.settings-page {
  &__header {
    .icon-arrow {
      transform: rotate(180deg);
      margin-right: 0.5em;
    }
  }

  a {
    text-decoration: none;
  }
}

@import "./Credits.scss";
