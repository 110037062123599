.observation-display__container {
  height: 100vh;
  background-color: $color-tertiary-light;
  .observation-display__header {
    .observation-display-header__container.observation-display-header__container {
      height: 20vh;
      div {
        height: 100%;
      }
    }
  }
  .observation-display__body {
    height: 80vh;
  }
}
