.alert {
  background-color: $color-background;
  border-radius: 1em;
  @include box-shadow-normal;

  color: $color-secondary;
  font-size: 0.75em;

  display: flex;
  align-items: center;
  padding: 1em;

  &__text {
    margin-left: 1em;
    margin-right: 1em;
  }

  .icon-close {
    margin-left: auto;
    height: 1.5em;
    width: 1.5em;
  }
}

@import "./ErrorAlert/ErrorAlert.scss";
@import "./SuccessAlert/SuccessAlert.scss";
