.graph-card {
  padding: 0 !important;

  &--no-data {
    opacity: 0.5;
  }

  &__header {
    padding: 0.25em 1em;
    border-bottom: 1px solid black;
  }

  &__axis {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 25px;
    background-color: black;
  }

  &__fullscreen {
    height: 100vh !important;
    width: 100vw;
    position: relative;
    overflow-x: hidden;

    border-radius: 0 !important;

    &-content {
      height: 100vw;
      width: 100vh;

      transform: rotate(90deg);
      transform-origin: 50vw 50vw;
      background-color: white;
    }

    &-axis {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      height: 25px;

      background-color: black;
    }
  }

  &__medication-label {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  &__info-drawer {
    text-align: center;

    span {
      font-size: 0.8em;
      line-height: 0.8em;
    }
  }
}

.graph-card__fullscreen-toggle {
  min-width: 0 !important;
}

.graph-card__title.graph-card__title {
  background-color: $color-secondary;
  border-radius: 999px;
  margin-right: auto;
  padding: 0 1em;
  min-width: 0;
  height: 2em;
  min-height: 0;

  color: $color-primary;
  font-size: 0.85em;

  display: flex;
  align-items: center;

  svg {
    height: 1em;
    width: 1em;
    margin-right: 0.5em;

    path {
      fill: white;
    }
  }
}
