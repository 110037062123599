.time-picker {
  background-color: $color-primary;
  border-radius: 0.5em;
  @include box-shadow-normal;

  padding: 0.5em;

  text-align: center;
  font-size: 1.25em;
  font-weight: 600;

  @include no-user-select;
  cursor: pointer;

  position: relative;

  box-sizing: border-box;

  &__input--ios {
    display: block;
    background-color: $color-primary;
    outline: none;
    min-width: calc(100% - 2rem);

    font-family: $font-family;

    color: black;
    font-weight: 600;
    line-height: 2;

    &--en::before {
      content: "Select a time";
    }

    &--fr::before {
      content: "Selectionnez une heure";
    }
  }

  &__label {
    font-size: 0.75em;
    &--drawer {
      text-align: center;
      font-weight: normal;
    }
  }
}

@import "./ScrollPicker/ScrollPicker.scss";
@import "./AMPMSelector/AMPMSelector.scss";
