.footer-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: auto;

  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  background-color: $color-tertiary-dark;
}
