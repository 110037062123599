.loader {
  &__background {
    &.loader__background {
      background-color: $color-filter;
    }
  }

  &__wrap {
    .icon-loader {
      width: 3em;
      height: 3em;
    }
  }
}
