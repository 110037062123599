$mp-unit: 0.5em;
$back-button-bevel-root: 0.5em;
$button-dimension-x: calc(#{$mp-unit} * 10);
$button-dimension-y: calc(#{$mp-unit} * 8.5);
$button-dimension-x-ipad: calc(#{$mp-unit} * 7);
$button-dimension-y-ipad: calc(#{$mp-unit} * 6);

.back-button {
  &__wrapper {
    filter: drop-shadow(0px 5px 0px $color-tertiary);
  }

  &__element {
    background-color: white;
    border: none;
    width: $button-dimension-x;
    height: $button-dimension-y;

    clip-path: polygon(
      // Top left corner points.
      0% #{$back-button-bevel-root},
      #{$back-button-bevel-root} 0%,
      // Top right corner points.
      calc(100% - #{$back-button-bevel-root}) 0%,
      100% #{$back-button-bevel-root},
      // Bottom right corner points.
      100% calc(100% - calc(2 * #{$back-button-bevel-root})),
      calc(100% - calc(2 * #{$back-button-bevel-root})) 100%,
      // Bottom left corner points.
      0% calc(100% - #{$back-button-bevel-root})
    );

    .icon {
      height: 50%;
      width: 50%;
      margin-left: 0.25em;
      margin-top: 0.25;
      transform: rotate(180deg);

      color: red;

      filter: drop-shadow(0px -5px 0px $color-tertiary);

      path {
        fill: red;
      }
    }
  }
}
