.alert--success {
  color: $color-success;

  .icon-checkbox {
    width: 2.5em;
    height: 2.5em;
  }

  path {
    fill: $color-success;
  }

  circle {
    stroke: $color-success;
  }
}
