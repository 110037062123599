.page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;

  &__header {
    top: 0;
    left: 0;
    right: 0;

    padding: 0.5em 1em;

    background-color: $color-secondary;
    color: $color-text-secondary;

    h1,
    h2,
    h3,
    h4 {
      margin: 0;
    }
  }

  &__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

@import "./CloseablePage/CloseablePage.scss";
