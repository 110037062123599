.scrolling {
  background-color: pink;
  border: 1px solid pink;
}

.timepicker-scroll {
  &__label {
    font-size: 0.9em;
    text-align: center;
  }

  &__input {
    margin: 0.5em 0;
    box-sizing: border-box;
    height: 5em;
    width: 100%;

    background-color: $color-primary;
    border-radius: 1em;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1em;
  }

  &__scroll-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    @include no-scrollbar();
    scroll-snap-type: y proximity;
    scroll-behavior: smooth;

    padding-top: 2em;
    padding-bottom: 2em;
  }

  &__value {
    box-sizing: border-box;

    scroll-snap-align: center;
    @include no-user-select();

    line-height: 1.1em;
    font-size: 1.25em;
    text-align: center;
    opacity: 0.15;

    transition: color 0.2s linear;
    &--selected {
      opacity: 1;
    }
  }

  &__unit {
    font-size: 0.9em;
  }
}
