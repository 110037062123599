.child-selector-button {
  svg {
    height: 0.75em;
    width: 0.75em;
    path {
      fill: $color-primary;
    }
  }
}
.child-selector-button__text {
  color: $color-primary;
}
.child-selector__menu.child-selector__menu {
  background-color: $color-primary;
}
.child-selector__menu__divider {
  width: 80%;
  border: 1px solid $color-tertiary-dark;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}
