.avatar {
  border-radius: 1em;
  border: 5px solid rgba($color-highlight, 0.5);

  min-width: 6em;
  min-height: 6em;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__image {
    width: 2.5em;
    height: 2.5em;
    border-radius: 1.25em;

    background-color: $color-tertiary-dark;

    margin-bottom: 0.5em;
  }
}
