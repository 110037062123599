.select {
  &__control {
    // Double selector necessary for enough specificity to overload default values.
    &.select__control {
      border: none;
      border-radius: 0;
      border-bottom: 2px solid $color-secondary;
      box-shadow: none;
    }
  }

  &__value-container {
    &.select__value-container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
