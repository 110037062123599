@import "./FollowUp";

.follow-up-page {
  hr {
    border-top: 2px;
  }

  .empty-follow-up {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__wrapper {
      flex-grow: 1;
      margin-bottom: 5em;
    }

    &__button {
      &.empty-follow-up__button {
        margin-top: 15%;
        margin-bottom: 0.5em;
      }
    }

    &__text {
      &.empty-follow-up__text {
        margin-bottom: 15%;
        font-weight: bold;
        color: $color-primary;
      }
    }
  }
}
