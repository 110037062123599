.summary-card {
  position: relative;
  &.summary-card {
    overflow: visible;
    padding: 0;
  }

  &__observations-badge {
    position: absolute;
    top: -2rem;
    left: calc(50% - 2rem);
    z-index: 10;

    height: 4rem;
    width: 4rem;
    border-radius: 2rem;
    background-color: $color-background;
    @include mui-shadow();

    display: flex;
    align-items: center;
    justify-content: center;

    color: $color-secondary;
    font-size: 2em;
  }

  &__header {
    position: relative;
    padding-top: 2.5em;
    padding-bottom: 1.5em;
    z-index: 1;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    overflow: hidden;

    display: flex;
    justify-content: center;

    color: $color-primary;
  }

  &__svg-background {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
    z-index: -1;
  }

  &__body {
    padding: 0.5em;

    &-section {
      border: 1px solid $color-secondary;
      border-radius: 0.5rem;

      padding: 0.5rem;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      text-align: center;
      font-size: 0.9em;

      h5 {
        margin-bottom: 0.75em;
      }

      hr {
        width: 1em;
      }
    }
  }
}
