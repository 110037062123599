.dashboard {
  background-color: $color-tertiary-light;
  overflow-x: hidden;

  // Padding to clear footer nav.
  padding-bottom: 4em;

  overflow-y: auto;
  @include no-scrollbar();

  &__head-section {
    padding: 1em;
  }

  .dashboard__card {
    background-color: $color-primary;
    padding: 1em;
    position: relative;

    &--summary {
      margin-top: 2em;
    }

    &--graph {
      height: 15em;
    }
  }

  .dashboard__body-section {
    &.dashboard__body-section {
      padding-left: 1em;
      padding-right: 1em;

      &--side-effect {
        width: 100%;
        padding-right: 0;
      }
    }
  }
}

@import "./Components/FollowUpSelector/FollowUpSelector.scss";
@import "./Components/SideEffectSelector/SideEffectSelector.scss";
@import "./Components/SummaryCard/SummaryCard.scss";
@import "./Components/PeriodSelector/PeriodSelector.scss";
@import "./Components/Graphs/GraphCard.scss";
